<template>
  <v-parallax
    dark
    :src="img"
    :height="height ? height : 100 "
  >
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-left"
          cols="12"
        >
          <h1 class="title page-title">
            {{ pagetitle }}
          </h1>
          <h4 class="subheading yellow--text text--lighten-3">
            {{ subheading }}
          </h4>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
export default {
  name : 'PageHeader',
  props : ['pagetitle', 'img', 'subheading', 'height']
}
</script>

<style lang="scss" scoped>
  .page-title {
    font-size: 1.5em !important;
  }

  .subheading {
    font-size: 1.2em !important;
    font-weight: initial;
  }
</style>