<template>
  <div >
    <!--<TitleComponent class="primary--text text-uppercase text-center" :title="`Maps`"/>-->
    <PageHeader pagetitle="Maps" :img="img"/>   
    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">-->
   
  <v-container fluid>
  <v-card
    elevation=""
    outlined
    rounded
    max-height="100%"
  > 
  <v-row>
   <v-divider></v-divider>
    <v-col
     
      v-for="(item, i) in this.TableData"   
      :key="i"
      class="d-flex child-flex"
      cols="4"
    >
     
      <v-card
        elevation="120"
        class="mx-auto"
        :max-width="$vuetify.breakpoint.lgAndUp ? 400 : 175"
        :max-height="400"
      >
       <!-- 
        <v-img
            class=""
            :height="$vuetify.breakpoint.lgAndUp ? 250 : 100"
            :width="$vuetify.breakpoint.lgAndUp ? 350 : 200"
            :src="$api_url+item.Url"
            :lazy-src="$api_url+item.Url"
            aspect-ratio=""
            :alt="item.Name"
            :title="item.Name"
        >
      
        </v-img>
       -->
        <v-card-title class="text-h6 font-weight-bold gray" >
          {{item.Name}}
        </v-card-title> 
        <v-divider></v-divider>
        <enlargeable-image 
          :src="$api_url+item.Url" 
          :src_large="$api_url+item.Url" 
          :height="$vuetify.breakpoint.lgAndUp ? 180 : 90"
          :width="$vuetify.breakpoint.lgAndUp ? 350 : 175"
        />
          
 
    </v-card>
   
   </v-col>
    
   
   <v-divider></v-divider>   
  </v-row>
  </v-card>
  </v-container>

    <Footer v-if="this.$route.name !== 'Home'"> </Footer>
  </div>
</template>

<script>
//import ongoingprojectData from "@/json/ongoingprojects.json";

import axios from 'axios' 
//import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'

import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import PageHeader from '@/components/PageHeader'


export default {

  name : 'Maps',
  components: {
    //TitleComponent,
    Footer,
   
    EnlargeableImage,
    PageHeader
   
  },
 
  data () {
    return {
      show: false,
      img : 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
      items:[],
      TableData:[],
     
     
    }

    },

    mounted(){
      //alert(this.items);
      this.TableData=this.getAllRecords(`/getAllRecords/maps/where ID > 0 /ID asc`);
      //alert(THIS.TableData);
     this.swiper.slideTo(3, 1000, false) 

    },  

    updated(){

     
    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },

    methods:{

      toggleshow(item){
        item.Show=!item.Show
      },
      formatData(value){
        //alert(value);!isNaN(Date.parse(value)) 
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
            
            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){
            
            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query){
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.TableData=response.data;
              //return this.items
            }
      })
      .catch(error => { console.log(error)})
    },  
  }
}

</script>

<style lang="scss" >

</style>